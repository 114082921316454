<template>
  <div class="film__details col-12">
    <button class="film__details-details__btn" @click="chooseFilm">Подробнее</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      testDataBtn: "testDataBtn",
    };
  },
  methods: {
      chooseFilm(){
      this.$emit('select-film', this.films)
      }
  },
};
</script>

<style lang="scss">
.film__details {
  text-align: center;
  &-details__btn {
    border: none;
    height: 2rem;
    width: 8rem;
    border-radius: 1rem;
    background: rgba(231, 218, 66, 100);
  }
}
</style>
